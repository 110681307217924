<template>
    <h4>{{ $t('general.comunicaciones') }}</h4>
    <div class="card">

        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <a href="#" class="info-box">
                        <span class="info-box-icon bg-danger"><i class="fas fa-university"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.centralita') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Correos')" href="#" class="info-box">
                        <span class="info-box-icon bg-danger"><i class="fas fa-envelope"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.correos') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a href="#" class="info-box">
                        <span class="info-box-icon bg-danger"><i class="fas fa-university"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.interpwgs') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a href="#" class="info-box">
                        <span class="info-box-icon bg-danger"><i class="fas fa-list"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.sms') }}</span>
                        </div>
                    </a>
                </div>
                <div class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('Sigue tu expediente')" href="#" class="info-box">
                        <span class="info-box-icon bg-danger"><i class="fas fa-house-user"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Sigue tu expediente</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default({
    props:{
        asignaciones: Array
    },
    setup() {
        
    },
    methods: {
        abrirConfiguracion(configuracion){
            this.$router.push({
                name: configuracion
            });
        }
    }
})
</script>
