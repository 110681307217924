<template>
    <div class="container-fluid">
        <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.title') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-6">
                    <configuracion-empresa />
                    <configuracion-facturacion />
                    <configuracion-comunicaciones />
                </div>
                <div class="col-md-6">
                    <configuracion-administracion />
                </div>


            </div>
        </section>
    </div>
</template>

<script>

//Iconos en https://fontawesome.com/v5/search?m=free&s=solid%2Cbrands
import ConfiguracionEmpresa from './ConfiguracionEmpresa.vue'
import ConfiguracionAdministracion from './ConfiguracionAdministracion.vue';
import ConfiguracionFacturacion from './ConfiguracionFacturacion.vue';
import ConfiguracionComunicaciones from './ConfiguracionComunicaciones.vue';



//import Dialog from 'primevue/dialog';

export default {
    components: {
    "configuracion-empresa": ConfiguracionEmpresa,
    "configuracion-administracion": ConfiguracionAdministracion,
    "configuracion-facturacion": ConfiguracionFacturacion,
    "configuracion-comunicaciones": ConfiguracionComunicaciones,
    
},
    data() {
        return {}
    }
}
</script>